import activity from '@imt/vue-activity/src/store';
import alerts from '@imt/vue-admin-menus/src/store/alerts';
import notifications from '@imt/vue-admin-menus/src/store/notifications';
import { asString, defaultParamMap } from '@imt/vue-list/src/utils/queryParams';
import toolbox from '@imt/vue-toolbox/src/store';
import utils, {axiosWithAuth, getCookieValue} from '@imt/vue-toolbox/src/utils';
import {vueVersioning} from '@imt/vue-versioning/src/store';
import Vue from 'vue';
import Vuex from 'vuex';

import notificationCenter from '@/store/notificationCenter';

Vue.use(Vuex);

export const authedAxios = axiosWithAuth();

export const getters = {
    originalLogo(_, _getters) {
        return _getters.selectedCompany.logos?.find(logo => logo.isOriginal);
    },
    selectedCompany(state) {
        return state.selectedCompany;
    },
};

export const actions = {
    async deleteCompany({dispatch}, companyId) {
        let abortController = new AbortController();
        await authedAxios.delete(`/companies/${companyId}/`);
        dispatch('fetchCompanies', {
            queryObject: '',
            signal: abortController.signal,
        });
    },
    async deleteLogo(_, attachmentId) {
        return authedAxios.delete(`/attachments/${attachmentId}/`);
    },
    async fetchCompanies({ commit }, {queryObject, signal}) {
        let queryString = asString(queryObject, {
                ...defaultParamMap,
                'text': 'filter[text]',
                'name': 'filter[name]',
                'licensed_states': 'filter[licensed_states]'
            }),
            url = '/companies/?include=logos';

        const response = await authedAxios.get(`${url}${queryString}`, {signal});

        commit('SET_STATE_VALUE', { field: 'companies', value: utils.dataFormatter.deserialize(response.data) });
        commit('SET_STATE_VALUE', { field: 'totalCompanyCount', value: response.data.meta.pagination.count });
    },
    async fetchCompany({commit}, companyId) {
        const response = await authedAxios.get(`/companies/${companyId}/?include=logos`);

        commit('SET_SELECTED_COMPANY', utils.dataFormatter.deserialize(response.data));
    },
    async saveCompany(context, {data, method = 'post'}) {
        const url = data.id ? `/companies/${data.id}/` : '/companies/',
            response = await authedAxios[method.toLowerCase()](
                url,
                utils.dataFormatter.serialize({stuff: {...data, type: 'Company'}}),
            );

        return utils.dataFormatter.deserialize(response.data);
    },
    async uploadFile(_, {data, url = 'attachments/'}) {
        let formData = new FormData();
        formData.append('file', data.file.file);
        formData.append('company_id', data.company_id);

        let response = await authedAxios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': getCookieValue(),
            }
        });

        return response.data;
    },
};

export const mutations = {
    CLEAR_SEARCH_FIELDS(state) {
        state.searchFields = {};
    },
    SET_SEARCH_FIELDS(state, searchField) {
        if ((typeof searchField.value !== 'boolean' && !searchField.value) || (Array.isArray(searchField.value) && !searchField.value.length)) {
            Vue.delete(state.searchFields, searchField.key);
        } else {
            Vue.set(state.searchFields, searchField.key, searchField.value);
        }
    },
    SET_SELECTED_COMPANY(state, company = {logos: [{isOriginal: false}]}) {
        state.selectedCompany = company;
    },
    SET_STATE_VALUE(state, data) {
        state[data.field] = data.value;
    },
};

export const state = () => {
    return {
        companies: [],
        totalCompanyCount: 0,
        searchFields: {},
        selectedCompany: {
            logos: [
                {
                    isOriginal: false
                }
            ]
        },
    };
};

export default new Vuex.Store({
    actions,
    getters,
    modules: {
        activity,
        alerts,
        notifications,
        notificationCenter,
        toolbox,
        vueVersioning
    },
    mutations,
    state: state(),
});
